<template>
  <div class="card p-1" :style="`font-family:${font},sans-serif`">
    <!-- <div>
      <p>{{ this.$route.params.id }}</p>
    </div> -->
    <div class="title head">
      <p class="h2" style="color: #558cef">รายละเอียดทรัพย์สินรับเข้า</p>
    </div>
    <hr />
    <div class="row">
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="เลขทะเบียน:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.equipment_code" disabled></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="รหัสทรัพย์สิน:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.e_number" disabled></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="SERIAL NUMBER:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.serial_number" disabled></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="ชื่อ:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.e_name" disabled></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="วันที่-เดือน-ปี:">
        <b-input disabled :value="this.$route.params.id.actiondate"></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="ปีงบประมาณ:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.budget_year" disabled></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="จำนวน:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.amount" disabled></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="หน่วยนับ:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.unittype" disabled></b-input>
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="ราคา + vat 7%:">
        <b-input id="idg" type="text" v-model="this.$route.params.id.unitprice_vat" disabled></b-input>
      </b-form-group>
      <div>
        <!-- <b-button class="ml-1" variant="primary" @click="$router.push({ name: '' })">เบิก</b-button>
        <b-button class="ml-1" variant="warning" @click="$router.push({ name: '' })">ยืม</b-button>
        <b-button class="ml-1" variant="info" @click="$router.push({ name: '' })">คืน</b-button> -->
        <div v-if="this.$route.params.id.flag_calculate === 'true'">
          <b-button
            class="ml-1"
            variant="secondary"
            :to="{ name: 'indepreciation', params: { id: this.$route.params } }"
          >
            ทะเบียนคุมทรัพย์สิน
          </b-button>
        </div>
        <div v-else>
          <b-button class="ml-1" variant="secondary" @click="alet_cc"> ทะเบียนคุมทรัพย์สิน </b-button>
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="h2" style="color: #558cef">ประวัติรายการทรัพย์สิน</div>
      <hr />
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'taskname'">
            <div v-if="props.row.taskname === 'รับเข้า'">
              <b-button variant="info">
                {{ props.row.taskname }}
              </b-button>
            </div>
            <div v-else-if="props.row.taskname === 'เบิก'">
              <b-button variant="warning">
                {{ props.row.taskname }}
              </b-button>
            </div>
            <div v-else-if="props.row.taskname === 'ยืม'">
              <b-button variant="danger">
                {{ props.row.taskname }}
              </b-button>
            </div>
            <div v-else-if="props.row.taskname === 'คืน'">
              <b-button variant="danger">
                {{ props.row.taskname }}
              </b-button>
            </div>

            <div v-else="props.row.status">
              <b-button variant="danger">
                {{ props.row.taskname }}
              </b-button>
            </div>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap">of {{ total }} entries</span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import _ from "lodash";
export default {
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: "วันที่-เดือน-ปี",
          field: "actiondate_th",
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
        },
        {
          label: "กลุ่มงาน",
          field: "workgroup",
        },
        {
          label: "ฝ่ายงาน",
          field: "department",
        },
        {
          label: "สภาพ",
          field: "status",
        },
        {
          label: "สถานะ",
          field: "taskname",
        },
      ],
      rows: [],
      total: "",
      pageLength: 10,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
    };
  },
  created() {
    _.isEmpty(this.$route.params.id) ? this.$router.push({ path: "/find-equipment" }) : true;

    this.get_data();
  },
  methods: {
    alet_cc() {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `ทรัพสินย์ต่ำกว่าเกณฑ์`,
        text: "ไม่คำนวนค่าเสือมราคาประจำปี",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    async get_data() {
      const { access_token } = await this.access_token();
      const url = `${API}historyTable?e_number=${this.$route.params.id.e_number}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      this.rows = res.data.message.data.map((element) => {
        if (element.taskname === "ส่งคืน") {
          return {
            ...element,
            actiondate_th: `${parseInt(element.updatedAt.split("-")[2])} ${
              this.month[parseInt(element.updatedAt.split("-")[1])]
            } ${Number(element.updatedAt.split("-")[0]) + 543}`,
          };
        } else {
          return {
            ...element,
            actiondate_th: `${parseInt(element.actiondate.split("-")[2])} ${
              this.month[parseInt(element.actiondate.split("-")[1])]
            } ${Number(element.actiondate.split("-")[0]) + 543}`,
          };
        }
      });
      this.total = res.data.message.total;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style></style>
